const resetMenu = () => {
  let menus = document.querySelectorAll('#menu-container > ul > li > a');
  menus.forEach(function(menu, index){
    menu.classList.remove('active');
  });
};

export const stickyMenu = () => {
  let scrollDownTop = (document.querySelector('.scroll-down').offsetTop + (window.innerWidth >= 992 ? 20 : 60));
  let menuContainer = document.querySelector('#menu-container');

  window.onscroll = () => {
    //window.pageYOffset == window.scrollY
    if(window.pageYOffset >= scrollDownTop){
      menuContainer.classList.add('sticky');
    }
    else{
      menuContainer.classList.remove('sticky');
    }

    resetMenu();
    let sections = document.querySelectorAll('.section');
    sections.forEach(function(section, index){
      if(window.pageYOffset >= section.offsetTop){
        //console.log(section.id, ': ', section.offsetTop);
        resetMenu();
        document.querySelector(`a[href="#${section.id}"]`).classList.add('active');
        //document.querySelector('a[href="#' + section.id + '"]').classList.add('active');
      }
    });
  };
};

export const scrollingMenu = () => {
  let menus = document.querySelectorAll('#menu-container > ul > li > a');

  menus.forEach(function(menu, index){
    menu.onclick = (e) => {
      e.preventDefault();
      /*resetMenu();
      e.target.classList.add('active');*/
      if(window.innerWidth < 992){
        document.querySelector('.menu-mobile > button').click();
      }

      window.scrollTo({
        top: (document.querySelector(e.target.hash).offsetTop + 0.5),
        left: 0,
        behavior: 'smooth'
      });
    };
  });
};

export const yearsOfExp = () => new Date().getFullYear() - 2013;