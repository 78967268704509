import React, { Component } from 'react';
import History from './History';
import Ability from './Ability';
import Portfolio from './Portfolio';

class Work extends Component{
  render(){
    return(
      <div id="work" className="section section-bg-2">
        <History />
        <Ability />
        <Portfolio />
      </div>
    );
  };
}

export default Work;