import React, { Component } from 'react';
import { yearsOfExp } from '../static/js/helpers.js';

class Portfolio extends Component{
  constructor(props){
    super(props);
    this.state = { isMore: false };
  };

  render(){
    let { isMore } = this.state;
    
    return(
      <div id="portfolio" className="container">
        <div className="row">
          <div className="col-12 col-lg-10">
            <h2>My Portfolios</h2>
            Across past {yearsOfExp()}+ years, i have done lots of projects. Below is my partial list of portfolio showcase.<br /><br />
          </div>
        </div>
        <div className="row portfolio-list">
          <div className="col-12 col-lg-5 portfolio-item">
            <h3><a target="_blank" rel="noopener noreferrer" href="https://www.rdcarkitek.com/" title="Click to view more details of RDC Arkitek Sdn Bhd" className="underline">RDC Arkitek Sdn Bhd</a></h3>
          </div>
          <div className="col-12 col-lg-5 portfolio-item">
            <h3><a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1_alL6sIM55Ml3TtPae3JQn_3SlPHebeK4_SgNYcOnjY/edit?usp=sharing" title="Click to view more details of Singapore Press Holdings" className="underline">Singapore Press Holdings</a></h3>
          </div>
          <div className="col-12 col-lg-5 portfolio-item">
            <h3><a target="_blank" rel="noopener noreferrer" href="https://www.citysquaremall.com.sg/" title="Click to view more details of City Square Mall" className="underline">City Square Mall</a></h3>
          </div>
          <div className="col-12 col-lg-5 portfolio-item">
            <h3><a target="_blank" rel="noopener noreferrer" href="/portfolio/axn/asia-got-talent-launch/" title="Click to view more details of AXN | Asia Got Talent" className="underline">AXN | Asia Got Talent</a></h3>
          </div>
          <div className="col-12 col-lg-5 portfolio-item">
            <h3><a target="_blank" rel="noopener noreferrer" href="/portfolio/animax/dangan-ronpa-3/" title="Click to view more details of Animax | Dangan Ronpa 3" className="underline">Animax | Dangan Ronpa 3</a></h3>
          </div>
          <div className="col-12 col-lg-5 portfolio-item">
            <h3><a target="_blank" rel="noopener noreferrer" href="/portfolio/animax/file-of-young/" title="Click to view more details of Animax | The OO-Kun Files" className="underline">Animax | The OO-Kun Files</a></h3>
          </div>
        </div>
        <div className="row portfolio-more">
          <div className="col-12 col-lg-10">
            <br />
            and more
            <div className={'portfolio-more-dots' + (!isMore ? ' active' : '')} onClick={() => { this.setState({ isMore: true }); }}>
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
            <div className={'portfolio-more-text' + (isMore ? ' active' : '')}>
              &nbsp;<span>i</span><span>n</span>
              &nbsp;<span>t</span><span>h</span><span>e</span>
              &nbsp;<span>f</span><span>u</span><span>t</span><span>u</span><span>r</span><span>e</span>
              &nbsp;<span>:</span><span>p</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default Portfolio;