import React, { Component } from 'react';
// import { resume } from '../static/media';

class Banner extends Component{
  render(){
    return(
      <div className="banner d-flex flex-column align-items-center justify-content-center">
        <div className="banner-box">
          <em>Hey, I'm</em>
          <h1>KAH CHEE</h1>
          {/* <a target="_blank" rel="noopener noreferrer" href={resume} title="Download resume" className="btn btn-download dark-gray">Download resume</a> */}
        </div>
        <a title="Scroll Down" href="#profile" className="scroll-down">
          <span className="material-icons">navigation</span>
        </a>
      </div>
    );
  };
}

export default Banner;