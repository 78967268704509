import React, { Component } from 'react';

class Menu extends Component{
  constructor(props){
    super(props);
    this.state = { isActive: false };
  };

  render(){
    let { isActive } = this.state;
    
    return(
      <nav id="menu-container">
        {/* Mobile Menu */}
        <div className={'menu-mobile' + (isActive ? ' active' : '')}><button type="button" onClick={() => { this.setState({ isActive: !isActive }); }}><span></span></button></div>
        
        {/* Desktop Menu */}
        <ul className="flex-column">
          <li><a href="#about" title="About">About</a></li>
          <li><a href="#work" title="Work">Work</a></li>
          {/*<li><a href="#abilities" title="Abilities">Abilities</a></li>
          <li><a href="#projects" title="Projects">Projects</a></li>*/}
          <li><a href="#contact" title="Contact">Contact</a></li>
        </ul>
      </nav>
    );
  };
}

export default Menu;