import React, { Component } from 'react';

class History extends Component{
  render(){
    return(
      <div id="history" className="container">
        <div className="row">
          <div className="col-12 col-lg-10"><h2>My Brief History</h2></div>
        </div>
        <div className="row history-list">
          <div className="col-12 col-lg-4">
            <h4>Equinix</h4>
            Full Stack Engineer, Since March 2022<br />
            Singapore
          </div>
          <div className="col-12 col-lg-6">
            Equinix, Inc. is an American multinational company that specializes in Internet connection and data centers, with 248 data centers in 31 countries on five continents. Our team is taken care of the case management and notification module Micro frontend for the in-house developed customer portal.
          </div>
          <div className="col-12 col-lg-10"><br /></div>
        </div>
        <div className="row history-list">
          <div className="col-12 col-lg-4">
            <h4>United Overseas Bank</h4>
            Full Stack Developer<br />
            March 2021 - February 2022<br />
            Singapore
          </div>
          <div className="col-12 col-lg-6">
            United Overseas Bank is the third largest bank in Singapore. Our team is responsible for an internal dashboard which is used by leadership during theirs management meeting.
          </div>
          <div className="col-12 col-lg-10"><br /></div>
        </div>
        <div className="row history-list">
          <div className="col-12 col-lg-4">
            <h4>Craft Digital</h4>
            Full Stack Developer<br />
            October 2019 - February 2021<br />
            Singapore
          </div>
          <div className="col-12 col-lg-6">
            Craft Digital is a digital agency. We developed an in-house iGaming platform then provide it to client who are interested and want to join this industry.
          </div>
          <div className="col-12 col-lg-10"><br /></div>
        </div>
        <div className="row history-list">
          <div className="col-12 col-lg-4">
            <h4>Singapore Press Holdings</h4>
            Software Developer<br />
            November 2017 - September 2019<br />
            Singapore
          </div>
          <div className="col-12 col-lg-6">
            Singapore Press Holdings is a media company. By the time I joined SPH, the company is experiencing a massive conversion from Lotus Notes to G Suite.
          </div>
          <div className="col-12 col-lg-10"><br /></div>
        </div>
        <div className="row history-list">
          <div className="col-12 col-lg-4">
            <h4>Patroids Creative Works</h4>
            Web Programmer<br />
            July 2015 - November 2017<br />
            Singapore
          </div>
          <div className="col-12 col-lg-6">
            Patroids Creative Works is a digital agency. We used to provide an all-round web solution to client from EDM, marketing / event site, microsite to web application.
          </div>
          <div className="col-12 col-lg-10"><br /></div>
        </div>
        <div className="row history-list">
          <div className="col-12 col-lg-4">
            <h4>Koo &amp; Co</h4>
            Web Programmer<br />
            October 2013 - March 2015<br />
            Johor, Malaysia
          </div>
          <div className="col-12 col-lg-6">
            Koo &amp; Co is an audit firm. We developed an in-house “Web Auditing Software” to be used by its own company and also targeting to roll out to the market.
          </div>
          <div className="col-12 col-lg-10"><br /></div>
        </div>
        <div className="row history-list">
          <div className="col-12 col-lg-4">
            <h4>Multimedia University</h4>
            Bachelor of I.T. (Hons), Obtained June 2013<br />
            Malacca, Malaysia
          </div>
          <div className="col-12 col-lg-6">
            After i completed my first step into programming world, I successfully obtained my Bachelor of I.T. (Hons) from Multimedia University and my 4 years of uni life end here.
          </div>
          <div className="col-12 col-lg-10"><br /></div>
        </div>
        <div className="row history-list">
          <div className="col-12 col-lg-4">
            <h4>Wincom IT Solution</h4>
            Internship<br />
            March 2013 - May 2013<br />
            Johor, Malaysia
          </div>
          <div className="col-12 col-lg-6">
            Wincom IT Solution is an IT firm. We aimed to provide customized MRP / ERP system to manufacturer.
          </div>
          <div className="col-12 col-lg-10"><hr /></div>
        </div>
      </div>
    );
  };
}

export default History;