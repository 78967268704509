import React, { Component } from 'react';
import { yearsOfExp } from '../static/js/helpers.js';
import { profile } from '../static/media';

class About extends Component{
  /*constructor(props){
    super(props);
    //this.state = { currYear: new Date().getFullYear() };
  };*/

  render(){
    //let { currYear } = this.state;

    return(
      <div id="about" className="section section-bg-1">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10"><h2>About</h2></div>
          </div>
          <div className="row align-items-center justify-content-center justify-content-sm-start">
            <div className="col-6 col-sm-4 col-lg-2"><img border="0" alt="Kong Kah Chee | Web App Developer" title="Kong Kah Chee | Web App Developer" width="100%" src={profile} className="" /></div>
            <div className="col-12 col-sm-8">
              <strong>I am a Web App Developer from Malaysia working for Equinix in sunny Singapore.</strong><br /><br />
              I've spent the past {yearsOfExp()}+ years working across different areas of digital development; front-end to back-end development, marketing site pages, electronic direct mail, to my current role which includes front and back-end development of the case management and notification module Micro frontend for the in-house developed customer portal.<br /><br /><br />
              <em>"You can do anything you set your mind to."
              - Benjamin Franklin</em>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default About;