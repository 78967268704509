export const resume = 'https://drive.google.com/file/d/1O065HzpoSArQk3lt-NUiLI4gWJEa3GDJ/view?usp=sharing';
export const banner = require('./banner.jpg');
export const profile = require('./profile.png');
//export const social_share = require('./social_share.jpg');
export const angularjs = require('./ability/angularjs.png');
export const bootstrap = require('./ability/bootstrap.png');
export const codeigniter = require('./ability/codeigniter.png');
export const css3 = require('./ability/css3.png');
export const docker = require('./ability/docker.png');
export const git = require('./ability/git.png');
export const google_app_maker = require('./ability/google_app_maker.png');
export const google_apps_script = require('./ability/google_apps_script.png');
export const graphql = require('./ability/graphql.png');
export const html5 = require('./ability/html5.png');
export const java = require('./ability/java.png');
export const javascript = require('./ability/javascript.png');
export const jira = require('./ability/jira.png');
export const jquery = require('./ability/jquery.png');
export const laravel = require('./ability/laravel.png');
export const monday = require('./ability/monday.png');
export const mongodb = require('./ability/mongodb.png');
export const mysql = require('./ability/mysql.png');
export const node_js = require('./ability/node_js.png');
export const php = require('./ability/php.png');
export const postgresql = require('./ability/postgresql.png');
export const react = require('./ability/react.png');
export const spring_boot = require('./ability/spring_boot.png');
export const vue_js = require('./ability/vue_js.png');