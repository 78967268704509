import React, { Component } from 'react';
import { SuperSEO } from 'react-super-seo';
//import { social_share } from '../static/media';

class Seo extends Component{
  render(){
    return(
      <>
        <SuperSEO
          title="Kong Kah Chee | Web App Developer" 
          description="I've spent the past 8+ years working across different areas of digital development; front-end to back-end development, ..." 
          lang="en" 
          openGraph={{
            ogImage: {
              ogImage: "https://kcabyss.xyz/static/media/social_share.jpg",
              ogImageAlt: "Kong Kah Chee | Web App Developer",
              ogImageWidth: 1200,
              ogImageHeight: 630,
              ogImageType: "image/jpeg"
            },
            ogUrl: "https://kcabyss.xyz",
            ogSiteName: "Kong Kah Chee | Web App Developer"
          }} 
          twitter={{
            twitterSummaryCardWithLargeImage: {
              summaryCardTitle: "Kong Kah Chee | Web App Developer",
              summaryCardImage: "https://kcabyss.xyz/static/media/social_share.jpg",
              summaryCardImageAlt: "Kong Kah Chee | Web App Developer",
              summaryCardSiteUsername: "Kong Kah Chee | Web App Developer"
            }
          }}
        >
          <meta name="keywords" content="Kong Kah Chee, Web App Developer, Full Stack Developer, Software Developer, Web Programmer, React" />
        </SuperSEO>
      </>
    );
  };
}

export default Seo;