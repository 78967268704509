import React, { Component } from 'react';
import {
  angularjs,
  bootstrap,
  codeigniter,
  css3,
  docker,
  git,
  google_app_maker,
  google_apps_script,
  graphql,
  html5,
  java,
  javascript,
  jira,
  jquery,
  laravel,
  monday,
  mongodb,
  mysql,
  node_js,
  php,
  postgresql,
  react,
  spring_boot,
  vue_js
} from '../static/media';

class Ability extends Component{
  render(){
    return(
      <div id="ability" className="container">
        <div className="row">
          <div className="col-12 col-lg-10"><h2>Abilities</h2></div>
        </div>

        <div className="row ability-list">
          <div className="col-12 col-lg-10">
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={html5} height="25" title="HTML" alt="HTML" />
                  HTML
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={css3} height="25" title="CSS" alt="CSS" />
                  CSS
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={javascript} height="25" title="JavaScript" alt="JavaScript" />
                  JavaScript
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={jquery} height="25" title="jQuery" alt="jQuery" />
                  jQuery
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={google_apps_script} height="25" title="Google Apps Script" alt="Google Apps Script" />
                  Google Apps Script
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={php} height="25" title="PHP" alt="PHP" />
                  PHP
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={node_js} height="25" title="Node.js" alt="Node.js" />
                  Node.js
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={java} height="25" title="Java" alt="Java" />
                  Java
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={graphql} height="25" title="GraphQL" alt="GraphQL" />
                  GraphQL
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={mysql} height="25" title="MySQL" alt="MySQL" />
                  MySQL
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={postgresql} height="25" title="PostgreSQL" alt="PostgreSQL" />
                  PostgreSQL
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={mongodb} height="25" title="MongoDB" alt="MongoDB" />
                  MongoDB
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={bootstrap} height="25" title="Bootstrap" alt="Bootstrap" />
                  Bootstrap
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={react} height="25" title="React" alt="React" />
                  React
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={vue_js} height="25" title="Vue.js" alt="Vue.js" />
                  Vue.js
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={angularjs} height="25" title="AngularJS" alt="AngularJS" />
                  AngularJS
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={google_app_maker} height="25" title="Google App Maker" alt="Google App Maker" />
                  Google App Maker
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={laravel} height="25" title="Laravel" alt="Laravel" />
                  Laravel
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={codeigniter} height="25" title="CodeIgniter" alt="CodeIgniter" />
                  CodeIgniter
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={spring_boot} height="25" title="Spring Boot" alt="Spring Boot" />
                  Spring Boot
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={git} height="25" title="Git" alt="Git" />
                  Git
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={docker} height="25" title="Docker" alt="Docker" />
                  Docker
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={jira} height="25" title="Jira" alt="Jira" />
                  Jira
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex ability-item">
                  <img src={monday} height="25" title="monday.com" alt="monday.com" />
                  monday.com
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-10"><hr /></div>
        </div>
      </div>
    );
  };
}

export default Ability;