import React, { Component } from 'react';
// import { resume } from '../static/media';

class Footer extends Component{
  render(){
    return(
      <footer id="contact" className="section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10">
              <h3>I occasionally take on freelance opportunities.</h3><br />
              Have an exciting project where you need some help?<br />
              Send me over a message, and let's chat.
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-10">
              <h4>Kong Kah Chee</h4><br />
              {/* You can call, WhatsApp or WeChat me at +65 9130 3139<br /> */}
              You can contact me at<br />
              <a href="mailto:kahchee_kong@hotmail.com" title="kahchee_kong@hotmail.com" className="links underline">kahchee_kong@hotmail.com</a><br /><br /><br />
              {/* <a target="_blank" rel="noopener noreferrer" href={resume} title="Download resume" className="btn btn-download">Download resume</a><br /><br /><br /> */}
            </div>
            <div className="col-12 col-lg-10">
              <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/kahchee0" title="Twitter" className="socicon"><i className="socicon-twitter"></i></a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/kahchee-kong" title="LinkedIn" className="socicon"><i className="socicon-linkedin"></i></a>
            </div>
          </div>
        </div>
      </footer>
    );
  };
}

export default Footer;