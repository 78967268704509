import React, { Component } from 'react';
/*import logo from './logo.svg';
import { Counter } from './features/counter/Counter';*/
import Banner from './components/Banner';
import Menu from './components/Menu';
import About from './components/About';
import Work from './components/Work';
import Footer from './components/Footer';
import Seo from './components/Seo';
import './App.css';
import './static/css/vendors/bootstrap-grid.min.css';
import './static/css/vendors/animate.css';
import './static/css/socicon.css';
import './static/css/styles.css';
import './static/css/responsive.css';
import { stickyMenu, scrollingMenu } from './static/js/helpers.js';

/*function App() {
  return (
    <div className="main-container">
      <Banner />
      <Menu />
      <About />
      <Footer />
    </div>
  );
}*/

class App extends Component{
  componentDidMount = () => {
    //document.title = 'Kong Kah Chee | Web App Developer';
    stickyMenu();
    scrollingMenu();
  };

  render(){
    return(
      <>
        <Seo />
        <div className="main-container">
          <Banner />
          <Menu />
          <About />
          <Work />
          <Footer />
        </div>
      </>
    );
  };
}

export default App;
